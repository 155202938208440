<template>
	<v-card class="rounded-xl pa-6 text-center login-page" elevation="0" outlined>
		<div>
			<v-stepper non-linear class="elevation-0 w-100 overflow-y-hidden" v-model="steps">
				<v-stepper-header class="elevation-0">
					<v-stepper-step :complete="steps > 1" :rules="[() => valid[0]]" step="1"> Account configureren </v-stepper-step>

					<v-divider></v-divider>

					<v-stepper-step :complete="steps > 2" :rules="[() => valid[1]]" step="2"> Programma selecteren </v-stepper-step>

					<v-divider></v-divider>

					<v-stepper-step :complete="steps > 3" :rules="[() => valid[2]]" step="3"> Rol kiezen </v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="1">
						<v-form v-model="valid[0]">
							<v-text-field class="theme-input" :rules="rules.name" v-model="data.fname" solo placeholder="Voornaam" background-color="#f1f8fa" title="Voornaam" type="text"></v-text-field>
							<v-text-field class="theme-input" :rules="rules.name" v-model="data.lname" solo placeholder="Achternaam" background-color="#f1f8fa" title="Achternaam" type="text"></v-text-field>
							<v-text-field v-model="data.passwd" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="rules.pass" :type="show ? 'text' : 'password'" class="theme-input" @click:append="show = !show" solo placeholder="Wachtwoord" background-color="#f1f8fa" title="Wachtwoord"></v-text-field>
							<v-text-field v-model="data.rpasswd" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="rules.pass" :type="show ? 'text' : 'password'" class="theme-input" @click:append="show = !show" solo placeholder="Wachtwoord herhalen" background-color="#f1f8fa" title="Wachtwoord"></v-text-field>
							<v-combobox :rules="[required]" :items="employers" item-text="title" item-value="id" v-model="data.employer" background-color="#f1f8fa" label="Selecteer een bestaande werkgever of voeg een nieuwe werkgever toe" class="elevation-0" hint="Indien jouw werkgever niet vermeld staat, voeg dan de naam van het bedrijf toe." clearable filled persistent-hint></v-combobox>
							<v-btn :disabled="!valid[0]" color="primary" @click="steps = 2"> Volgende stap </v-btn>
						</v-form>
					</v-stepper-content>

					<v-stepper-content step="2">
						<v-form  lazy-validation v-model="valid[1]">
							<v-switch v-model="newprogram" flat label="Wil u een nieuw programma aanmaken?"></v-switch>
							<v-text-field v-if="newprogram" class="theme-input" :rules="rules.name" v-model="data.program" solo placeholder="Voer de naam van uw nieuwe programma in." background-color="#f1f8fa" title="Programma naam" type="text"></v-text-field>
							<v-btn :disabled="!valid[1]" color="primary" @click="steps = 3"> Volgende </v-btn>
							<v-btn text @click="steps = 1"> Terug </v-btn>
						</v-form>
					</v-stepper-content>

					<v-stepper-content step="3">
						<!-- <v-form v-model="valid[2]"> -->
						<v-skeleton-loader v-if="loading" type="paragraph"></v-skeleton-loader>
						<v-select v-else label="Selecteer uw functie" v-model="data.role" hint="Let op: U krijgt alleen toegang tot de projecten waarvoor u bent uitgenodigd binnen uw actieve functie. Deze kunt u later nog wijzigen." persistent-hint :items="roles" item-text="label" class="elevation-0" item-value="val"></v-select>
						<v-btn text @click="steps = 2"> Terug </v-btn>
						<v-btn type="button" :loading="loading" color="primary" @click="submit"> Voltooien </v-btn>

						<!-- </v-form> -->
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</div>
	</v-card>
</template>
<script>
import api from "../../services/api";
import config from '../../config'
export default {
	computed: {
		roles() {
			if (this.newprogram) {
				return [
					{
						label: "Organisator",
						val: "OG"
					}
				];
			} else {
				return [
					{
						label: "Organisator",
						val: "OG"
					}, {
						label: "Procesmanager/Clustermanager/Lijnmanager",
						val: "PM"
					}, {
						label: "Projectleider",
						val: "PU"
					},
				];
			}
		}
	},
	data: () => ({
		steps: 1,
		show: false,
		newprogram: true,
		data: {
			token: '',
			passwd: '',
			rpasswd: '',
			fname: '',
			lname: '',
			email: '',
			program: '',
			role: 'OG',
			employer: ''
		},
		programs: [],
		employers: [],

		loading: false,
		valid: [false, false, true],
		rules: {
			mail: [
				(v) => !!v || "Verplicht",
				(v) => config.regex.mail.test(v) || "Incorrect emailadres",
				(v) => v.split('@', 2)[1]?.includes(".") || "Domeinextensie ontbreekt",
			],
			pass: [
				v => !!v || 'Verplicht',
				v => (v && v.length >= 2) || 'Minimaal 8 tekens',
				v => (v && v.length <= 64) || 'Maximaal 64 tekens',
				v => (/[a-z]/.test(v)) || 'Wachtwoord moet ten minste één kleine letter bevatten',
				v => (/[A-Z]/.test(v)) || 'Wachtwoord moet minstens één hoofdletter bevatten',
				v => (/[0-9]/.test(v)) || 'Wachtwoord moet ten minste één getal bevatten',
				v => (/[@$!#%*?&]/.test(v)) || 'Wachtwoord moet ten minste een van de volgende speciale tekens bevatten: @$!%*?&',
				v => config.regex.password.test(v) || 'Het wachtwoord moet tussen 8 en 64 tekens lang zijn en ten minste één hoofdletter, kleine letter, cijfer en één van de volgende speciale tekens bevatten: @$!%*?&',
			],
			name: [
				value => !!value || 'Verplicht.',
				value => (value && value.length >= 2) || 'Minimaal 2 karakters',
				value => (value && !(value.length > 64)) || 'Maximaal 64 karakters',
			],
			required: [
				value => !!value || 'Verplicht.',
			]
		},

	}),
	methods: {
		required(value) {
			if (value instanceof Array && value.length == 0) {
				return 'Verplicht.';
			}
			return !!value || 'Verplicht.';
		},
		submit() {
			if (this.valid) {
				this.loading = true

				if (typeof this.data.employer === 'object') {
					this.data.employer = this.data.employer.id;
				}

				this.data.program = this.data.program === "" ? false : this.data.program;
				api.post(`${config.url.api}/v1/auth/register`, this.data).then((res) => {
					switch (res.data.s) {
						case 4:

							this.$toast.success('Registratie successvol, je kunt nu inloggen')
							this.$router.push("/inloggen")
							break;
						case 5:
							this.$toast.error('E-mailadres is al in gebruik!')
							this.$router.push("/inloggen")

							break;
						case 50:
							this.$router.push("/inloggen")
							this.$toast.error('Je registratie token is verlopen.')
							break;
					}
				}).catch(() => {
					this.loading = false
					this.$toast.error('Ongeldig registratie token of server fout!')
				})
			}
		},

		getEmployers(s = null) {
			if (s == null) {
				api.get(`${config.url.api}/v1/employers/0`).then((res) => {
					if (res.data.success)
						this.employers = res.data.data;
				})
			}
		}
	},
	mounted() {
		const urlParams = new URLSearchParams(window.location.search);
		this.data.token = urlParams.get('token');

		this.getEmployers();
	}
}
</script>